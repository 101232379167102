import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import YouTube from 'react-youtube';
export const _frontmatter = {
  "path": "/blog/exploring-docker-desktop",
  "date": "27th March 2024",
  "title": "A practical guide to Docker contexts",
  "summary": "Exploring Docker Desktop, its ecosystem and using Docker Contexts to bring new value to the table.",
  "author": "Karl Hepworth",
  "tag": "Informational",
  "tagColor": "green",
  "tags": [{
    "name": "presentation"
  }, {
    "name": "informational"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`How we got here`}</h2>
    <p>{`Containers and Docker took the world by storm. So much so that many technical people and agencies became dependent on it in the years after its release.`}</p>
    <p>{`After some time Docker was facing some financial trouble. To get around this, it introduced a licensing model to keep the project sustainable.`}</p>
    <p>{`Given a market monopoly and the decisions that led to it, this was the turning point for Docker.`}</p>
    <h2>{`Moving containers into the future`}</h2>
    <p>{`In the meantime, open source alternatives have been in the works. New tools are developing, providing a Docker-like experience in emerging tools.`}</p>
    <p>{`We are now able to replace individual pieces of this technology stack, with more options on the way in an evolving ecosystem.`}</p>
    <p>{`I presented a knowledge piece at DrupalSouth 2024 in Sydney discussing a variety of benefits and features available when introducing some of these changes to your development workflow.`}</p>
    <p>{`In the video below, I expand on one useful construct Docker provides - the Context. I also explore reasons you may want to switch away from Docker Desktop, and how you would achieve this.`}</p>
    <h2>{`Watch the video`}</h2>

    <YouTube videoId="SxBA4I57WPA" opts={{
      height: '420',
      width: '100%',
      playerVars: {
        start: '979'
      }
    }} mdxType="YouTube" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      